$light: rgba(242, 238, 233, 0.9);
$dark: rgba(38, 50, 57, 1);
$colored: rgba(#fe5f75, 1);

.app {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(315deg, #fc9842 0%, #fe5f75 74%);
  background-size: 200%;
  animation: gradient 10s ease infinite;

  > div {
    position: relative;
  }

  h1 {
    font-size: 4rem;
    font-weight: 300;
    font-family: 'Rubik', sans-serif;
    line-height: 1.3;
    margin-top: 0;
    color: $light;
    text-shadow: 0 0.02em 0.02em rgba($dark, 0.2);

    span {
      display: block;
    }
  }
}

.social {
  position: absolute;
  right: 0;
  top: 1rem;

  a {
    display: inline-flex;
    margin-left: 1.5rem;
    color: $dark;

    &:hover {
      color: $light;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
